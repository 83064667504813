import { defineStore } from "pinia";

export const useFiltersStore = defineStore("eventsStore", {
  state: () => ({
    selectedCity: null,
    cities: ref([]),
    selectedDate: null,
    dates: ref([]),
    allDates: ref([]),
    selectedDropdowns: { theme: null, venue: null, target: null, format: null },
    selectedThemes: ref([]),
    locations: ref([]),
    themes: ref([]),
    targets: ref([]),
    formats: ref([]),
    events: ref([]),
    filteredEvents: ref([]),
  }),
  actions: {
    setSelectedCity(value) {
      this.selectedCity = value;
    },
    setSelectedDate(value) {
      this.selectedDate = value;
    },
    setSelectedDropdowns(key, value) {
      this.selectedDropdowns[key] = value;
    },
    setFilteredEvents(value) {
      this.filteredEvents = value;
    },
    setEvents(value) {
      this.events = value;
    },
    setThemeDropdown(value) {
      if (value === null) {
        this.selectedThemes = [];
      } else {
        this.selectedThemes = [...this.selectedThemes, value];
      }
    },
    removeTheme(value) {
      console.log(value);
      console.log("clicked");
      this.selectedThemes = this.selectedThemes.filter(
        (theme) => theme !== value
      );
    },
    setCitiesArray() {
      this.cities = [];
      this.filteredEvents?.forEach((event) => {
        if (!this.cities.includes(event.location.city.name)) {
          this.cities.push(event.location.city.name);
        }
      });
    },
    setDatesArray() {
      this.dates = [];
      this.filteredEvents?.forEach((event) => {
        if (!this.dates.includes(event.term)) {
          this.dates.push(event.term);
        }
      });
      this.dates.sort();
    },
    setAllDatesArray() {
      this.allDates = [];
      this.events?.forEach((event) => {
        if (!this.allDates.includes(event.term)) {
          this.allDates.push(event.term);
        }
      });
      this.allDates.sort();
    },
    setLocationsArray() {
      this.locations = [];
      this.filteredEvents?.forEach((event) => {
        if (!this.locations.includes(event.location.name)) {
          this.locations.push(event.location.name);
        }
      });
      this.locations.sort();
    },
    setThemesArray() {
      this.themes = [];
      this.filteredEvents?.forEach((event) => {
        event.categories.forEach((category) => {
          if (!this.themes.includes(category.name)) {
            this.themes.push(category.name);
          }
        });
      });
      this.themes.sort();
      console.log("set themes");
    },
    setTargetsArray() {
      this.targets = [];
      this.filteredEvents?.forEach((event) => {
        event.targets.forEach((target) => {
          if (!this.targets.includes(target.name)) {
            this.targets.push(target.name);
          }
        });
      });
      this.targets.sort();
    },
    setFormatsArray() {
      this.formats = [];
      this.filteredEvents?.forEach((event) => {
        event.formats.forEach((target) => {
          if (!this.formats.includes(target.name)) {
            this.formats.push(target.name);
          }
        });
      });
      this.formats.sort();
    },

    filterEvents() {
      this.setFilteredEvents(this.events);

      if (this.selectedCity) {
        this.setFilteredEvents(
          this.filteredEvents.filter(
            (event) => event.location.city.name === this.selectedCity
          )
        );
      }
      if (this.selectedDate) {
        this.setFilteredEvents(
          this.filteredEvents.filter(
            (event) => event.term === this.selectedDate
          )
        );
      }
      // if (this.selectedDropdowns.venue) {
      //   this.setFilteredEvents(
      //     this.filteredEvents.filter(
      //       (event) => event.location.name === this.selectedDropdowns.venue
      //     )
      //   );
      // }
      // if (this.selectedDropdowns.theme) {
      //   this.setFilteredEvents(
      //     this.filteredEvents.filter((event) => {
      //       for (const category of event.categories) {
      //         if (category.name === this.selectedDropdowns.theme) {
      //           return true;
      //         }
      //       }
      //       return false;
      //     })
      //   );
      // }
      if (this.selectedThemes && this.selectedThemes.length > 0) {
        console.log("inside selected Themes");
        console.log(this.selectedThemes);
        console.log(this.filteredEvents);
        console.log(this.filteredEvents[0].categories[0].name);
        this.setFilteredEvents(
          this.filteredEvents.filter((event) => {
            for (const category of event.categories) {
              if (this.selectedThemes.includes(category.name)) {
                return true;
              }
            }
            return false;
          })
        );
      }
      if (this.selectedDropdowns.target) {
        this.setFilteredEvents(
          this.filteredEvents.filter((event) => {
            for (const target of event.targets) {
              if (target.name === this.selectedDropdowns.target) {
                return true;
              }
            }
            return false;
          })
        );
      }
      if (this.selectedDropdowns.format) {
        this.setFilteredEvents(
          this.filteredEvents.filter((event) => {
            for (const format of event.formats) {
              if (format.name === this.selectedDropdowns.format) {
                return true;
              }
            }
            return false;
          })
        );
      }
    },
  },
});
