<script setup>
const props = defineProps(["listitems", "name", "slug"]);

const isOpen = ref(false);

const store = useFiltersStore();

const { setThemeDropdown, setDatesArray, filterEvents, setThemesArray } = store;

const selectedThemes = computed(() => store.selectedThemes);

const dropdownRef = ref(null);

const onDropdownSelect = (item) => {
  setThemeDropdown(item);
  filterEvents();
  setDatesArray();
};

// Function to detect click outside the dropdown
const handleClickOutside = (event) => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    isOpen.value = false;
  }
};

// Add the event listener to the document when the component is mounted
onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

// Remove the event listener when the component is unmounted
onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

<template>
  <div
    ref="dropdownRef"
    class="tag dropdown"
    @click="isOpen = !isOpen"
    :class="{ open: isOpen }"
  >
    <div class="flex-center tag__head">
      {{ name }}
      <img class="arrow" src="/images/arrow.svg" :class="{ rotate: isOpen }" />
    </div>
    <div v-if="isOpen" class="dropdown__list">
      <p @click="onDropdownSelect(null)" class="underline">zrušit filtr</p>
      <p
        v-for="item in listitems"
        @click="onDropdownSelect(item)"
        :class="{ filterchosen: selectedThemes.includes(item) }"
      >
        {{ item }}
      </p>
    </div>
  </div>
</template>

<style>
.dropdown {
  .arrow {
    transform: rotate(90deg);
    z-index: 1;
  }
  .rotate {
    transform: rotate(270deg);
    transition-duration: 350ms;
  }
  .filterchosen {
    pointer-events: none;
    cursor: default !important;
  }
}
</style>
