<script setup>
const props = defineProps(["sequence"]);
</script>

<template>
  <div
    :class="{
      grid__item: true,
    }"
  >
    <div
      :class="{
        'grid__item-multiple': props.sequence?.size || props.sequence?.height,
        'grid__item-inner': true,
        'grid__item-inner--bg-white': props.sequence?.bg === 'white',
        'grid__item-inner--blur-bg': props.sequence?.blur,
      }"
      :style="{
        textAlign: `${sequence?.align}`,
        width: `${sequence?.size * 100}%`,
        height: `${sequence?.height * 100}%`,
      }"
    >
      <a
        v-if="sequence?.logo"
        :href="sequence?.logo[1]"
        target="_blank"
        rel="noopener noreferrer"
        class="grid__item-inner__logo-link"
      >
        <img
          :src="`/images/logo/${sequence?.logo[0]}`"
          class="grid__item-inner__logo-link--logo"
          :style="{ width: `${sequence?.logo[2]}%` }"
        />
      </a>

      <div v-if="sequence?.text" v-html="sequence?.text"></div>
    </div>
  </div>
</template>

<style>
.grid__item {
  flex: 1;
  aspect-ratio: 1 / 1;
  box-sizing: border-box;
  color: white;
  position: relative;
  text-align: center;
  h2,
  h3,
  h6 {
    margin: 0;
  }
}

.grid__item-multiple {
  position: absolute;
  height: 100%;
}

.grid__item-inner {
  align-content: center;
  height: 100%;
  width: 100%;
  padding-left: 36px;
  padding-right: 36px;
  box-sizing: border-box;

  @media (max-width: 1501px) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media (max-width: 1201px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }
  @media (max-width: 540px) {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.grid__item-inner__logo-link--logo {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.grid__item-inner__logo-link {
  z-index: 2;
  position: relative;
}

.grid__item-inner--bg-white {
  background-color: white;
  color: black;
}
.grid__item-inner--blur-bg {
  backdrop-filter: blur(32px);
  transition-duration: 350ms;

  &:hover {
    backdrop-filter: none;
  }
}
</style>
