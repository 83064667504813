export const paragraphs = [
  {
    title: "Pro všechny, kteří se zajímají o AI a její využití.",
    content: `
      ‍<p>
        Ústřední linkou Dnů AI je představení umělé inteligence v návaznosti na
        aktuální dění a vývoj této technologie. Významná část programu se věnuje
        praxi a konkrétním příkladům využití AI, a to napříč obory lidské činnosti
        a v různých kontextech.
      </p>
      <p>
        Atraktivní obsah přináší akce zástupcům firem, veřejných institucí, stejně
        jako akademikům, studentům a veřejnosti. Právě šíře záběru Dnů AI otevírá
        prostor pro nahlédnutí na umělou inteligenci v netradičních souvislostech
        s důrazem na její mezioborovost.
      </p>
    `,
  },
  {
    title: "Třetí ročník s významnou podporou",
    content: `
      <p>
        Festival umělé inteligence Dny AI 2024 se koná <b>pod záštitou prezidenta České republiky Petra Pavla.</b>
      </p>
      <p>
        Záštitu nad akcí převzalo také <b>Ministerstvo průmyslu a obchodu, Ministerstvo vnitra</b> a <b>Hlavní město Praha</b>.
      </p>
      <p>
        Děkujeme za podporu!
      </p>
    `,
  },
  {
    content: `          
      <p>
        <a
          href="http://2023.dny-ai.cz/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Dny AI 2023</a
        >
        úspěšně navázaly na
        <a
          href="http://2022.dny-ai.cz/"
          target="_blank"
          rel="noopener noreferrer"
        >
          první ročník</a
        >. Nabitý program ve čtyřech největších českých městech postupně
        nabídl na 100 akcí a přilákal více než 7 000 lidí.
      </p>`,
  },
];
