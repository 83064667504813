<script setup>
const props = defineProps(["text", "stroked", "id"]);
const divs = ref(null);

const observerCallback = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.querySelector("h2").classList.add("change");
    } else {
      entry.target.querySelector("h2").classList.remove("change");
    }
  });
};

onMounted(() => {
  const observer = new IntersectionObserver(observerCallback, {
    root: null,
    threshold: 0.5, // 50% of the element should be in the viewport to trigger the callback
  });

  observer.observe(divs.value);
  onUnmounted(() => {
    observer.disconnect();
  });
});
</script>

<template>
  <div class="strip" ref="divs">
    <h2
      :id="id"
      :class="{
        'cutout-text': true,
        stroked: props.stroked,
      }"
    >
      {{ props.text }}
    </h2>
  </div>
</template>

<style scoped>
.box {
  height: 300px;
  margin: 10px 0;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.red {
  background-color: red;
}

.strip {
  width: 100%;
  overflow: hidden;
  position: relative;
}

h2 {
  margin: 0;
  font-weight: 900;
  font-size: 5rem;
  @media (max-width: 767px) {
    font-size: 3.5rem;
  }
  @media (min-width: 1501px) {
    font-size: 8rem;
  }
}

.red {
  background-color: red;
}

.cutout-text {
  text-transform: uppercase;
  white-space: nowrap;
  display: inline-block;
}

.stroked {
  color: black;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  transform: translateX(-4000px);
  @media (max-width: 767px) {
    display: none;
  }
}

.change {
  transform: translateX(-2000px);
  transition-duration: 2000ms;
}
</style>
