<script setup>
useHead({
  script: [
    {
      type: "text/javascript",
      innerHTML: `(function(){
var i,e,d=document,s="script";i=d.createElement("script");i.async=1;i.charset="UTF-8";
i.src="https://cdn.curator.io/published/28d61a5a-407d-45de-a9ed-5d883a19f223.js";
e=d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
})();`,
      body: true,
    },
  ],
});
</script>

<template>
  <div class="text-container">
    <h2>
      Aktuálně<br />
      na Dnech AI
    </h2>
  </div>
  <div id="curator-feed-default-feed-layout">
    <a href="https://curator.io" target="_blank" class="crt-logo crt-tag"
      >Powered by Curator.io</a
    >
  </div>
</template>

<style scoped>
h2 {
  margin-bottom: 0rem;
  margin-top: 3rem;
}
</style>
