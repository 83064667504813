<script setup>
const props = defineProps(["listitems", "name", "slug"]);

const isOpen = ref(false);

const store = useFiltersStore();

const {
  setSelectedDropdowns,
  setDatesArray,
  filterEvents,
  setLocationsArray,
  setThemesArray,
  setTargetsArray,
} = store;

const selectedDropdowns = computed(() => store.selectedDropdowns);

// Use a ref to identify the dropdown DOM element
const dropdownRef = ref(null);

const onDropdownSelect = (slug, item) => {
  setSelectedDropdowns(slug, item);
  filterEvents();
  setDatesArray();
  setLocationsArray();
  setThemesArray();
  setTargetsArray();
};

// Function to detect click outside the dropdown
const handleClickOutside = (event) => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    isOpen.value = false;
  }
};

// Add the event listener to the document when the component is mounted
onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

// Remove the event listener when the component is unmounted
onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

<template>
  <div
    ref="dropdownRef"
    class="tag dropdown"
    @click="isOpen = !isOpen"
    :class="{ open: isOpen }"
  >
    <div class="flex-center tag__head">
      {{
        selectedDropdowns[slug] != null ? selectedDropdowns[props.slug] : name
      }}
      <img class="arrow" src="/images/arrow.svg" :class="{ rotate: isOpen }" />
    </div>
    <div v-if="isOpen" class="dropdown__list">
      <p @click="onDropdownSelect(slug, null)" class="underline">
        zrušit filtr
      </p>
      <p
        v-for="item in listitems"
        @click="onDropdownSelect(slug, item)"
        :class="{ filterchosen: selectedDropdowns[props.slug] == item }"
      >
        {{ item }}
      </p>
    </div>
  </div>
</template>

<style>
.arrow {
  transform: rotate(90deg);
  z-index: 1;
}
.rotate {
  transform: rotate(270deg);
  transition-duration: 350ms;
}
</style>
