<script setup>
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
</script>

<template>
  <div class="scroll-to-top-button" @click="scrollToTop">
    <img class="arrow" src="/images/arrow.svg" />
  </div>
</template>

<style scoped>
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  z-index: 4;
  .arrow {
    transform: rotate(270deg);
    filter: invert(1);
  }
}

.scroll-to-top-button:hover {
  transform: scale(1.1);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}
</style>
