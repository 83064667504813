<script setup>
const props = defineProps([
  "itemsPerRow",
  "sequence",
  "mobileSequence",
  "padding",
  "absolute",
]);

const itemsPerRow = ref(props.itemsPerRow);
const sequence = ref(props.sequence);
const lastItem = ref(0);
const numberOfRows = ref(0);
const gridItems = ref([]);

function computeGridItems() {
  lastItem.value = sequence.value[sequence.value?.length - 1]?.start + 1;
  numberOfRows.value = Math.ceil(lastItem.value / itemsPerRow.value);
  gridItems.value = Array(itemsPerRow.value * numberOfRows.value).fill("");
}

function updateItemsPerRow() {
  itemsPerRow.value = window.innerWidth <= 1200 ? 4 : props.itemsPerRow;
  sequence.value =
    window.innerWidth <= 1200
      ? props.mobileSequence || props.sequence
      : props.sequence;
  computeGridItems();
}

onMounted(() => {
  if (props.mobileSequence) {
    updateItemsPerRow();
    window.addEventListener("resize", updateItemsPerRow);
  } else {
    computeGridItems();
  }
});

onUnmounted(() => {
  window.removeEventListener("resize", updateItemsPerRow);
});

const rows = computed(() => {
  const result = [];
  for (let i = 0; i < gridItems.value.length; i += itemsPerRow.value) {
    result.push(gridItems.value.slice(i, i + itemsPerRow.value));
  }
  return result;
});

function isPresent(rowIndex) {
  const sequenceItem = sequence.value.find((seq) => seq.start === rowIndex);
  return sequenceItem;
}
</script>

<template>
  <div
    :class="{
      relative: props.absolute,
    }"
    class="grid-item"
  >
    <div
      :class="{
        'grid-container': true,
        'padding-top': props.padding,
        absolute: props.absolute,
      }"
    >
      <div class="grid-row" v-for="(row, rowIndex) in rows" :key="rowIndex">
        <GridItem
          v-for="(item, itemIndex) in row"
          :key="rowIndex * itemsPerRow + itemIndex"
          :sequence="isPresent(rowIndex * itemsPerRow + itemIndex)"
        />
      </div>
    </div>
  </div>
</template>

<style>
.grid-item {
  .padding-top {
    padding-top: 25vh;
  }
  .grid-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .grid-row {
    display: flex;
    width: 100%;
  }
  .relative {
    z-index: 2;
  }
}
</style>
